<template>
  <component
    v-if="config"
    :is="config.component"
    :gradientClass="gradientClass"
    :disabled="disabled"
    class="cta"
    v-bind="config.data"
    v-on="$listeners"
  >
    <template v-slot:pre-label>
      <Arrow class="pre-label" :class="{'pre-label-reverse': reverse}"/>
    </template>
  </component>
</template>

<script>
import GradientClass from '@/mixins/GradientClass'
import Arrow from '@/assets/img/cta-arrow.svg'
import ExternalLink from '@/atoms/CtaExternalLink'
import InternalLink from '@/atoms/CtaInternalLink'
import Download from '@/atoms/CtaDownload'
import CtaButton from '@/atoms/CtaButton'
import MailTo from '@/atoms/CtaMailTo'
import StartProjectButton from '@/atoms/CtaStartProject'

export default {
  name: 'Cta',
  mixins: [GradientClass],
  props: {
    label: { type: String },
    action: { type: String },
    to: { type: [Object, String], required: false },
    download: { type: Object, required: false },
    reverse: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    href: { type: String, default: undefined }
  },
  components: { Arrow },
  computed: {
    config () {
      switch (this.action) {
        case 'button':
          return {
            component: CtaButton,
            data: {
              label: this.label
            }
          }
        case 'external':
          return {
            component: ExternalLink,
            data: {
              label: this.label,
              href: this.href
            }
          }
        case 'internal':
          return {
            component: InternalLink,
            data: {
              label: this.label,
              to: this.to
            }
          }
        case 'download':
          return {
            component: Download,
            data: {
              label: this.label,
              download: this.download
            }
          }
        case 'login':
          return {
            component: InternalLink,
            data: {
              label: this.label,
              to: '/login'
            }
          }
        case 'events-calendar':
          return {
            component: InternalLink,
            data: {
              label: this.label,
              to: '/events-calendar'
            }
          }
        case 'careers':
          return {
            component: InternalLink,
            data: {
              label: this.label,
              to: '/careers'
            }
          }
        case 'products-showcase':
          return {
            component: InternalLink,
            data: {
              label: this.label,
              to: '/products-showcase'
            }
          }
        case 'send-email':
          return {
            component: MailTo,
            data: {
              label: this.label,
              subject: this.$attrs.subject,
              mail: this.$attrs.mail
            }
          }
        case 'start-project':
          return {
            component: StartProjectButton,
            data: {
              label: this.label
            }
          }
        default:
          return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cta {
  text-decoration: none;
  position: relative;
  padding-left: 1.4em;
  outline: none;
}

.pre-label {
  position: absolute;
  left: 0;
  top: 50%;
  width: 0.65em;
  height: 0.65em;
  margin-top: -0.325em;

  opacity: 1;
  will-change: transform;

  .cta:hover & {
    animation: arrowHover 0.5s;
  }

  &.pre-label-reverse {
    transform: scale(-1);

    .cta:hover & {
      animation: arrowHoverReverse 0.5s;
    }
  }
}

</style>

<template>
  <a
    class="text-strong"
    :href="href"
    target="_blank"
    rel="noopener noreferrer"
  >
    <slot name="pre-label"></slot>
    <span :class="gradientClass">{{label}}</span>
  </a>
</template>

<script>
export default {
  name: 'CtaInternalLink',
  props: {
    label: { type: String },
    href: { type: String },
    gradientClass: { type: String }
  }
}
</script>
